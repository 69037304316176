.header {
  height: calc(100vh - 65px);
  background: url("../../img/header_mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  @media screen and (min-width: $breakpoint-md) {
    height: calc(100vh - 65px);
  }

  @media screen and (min-width: $breakpoint-lg) {
    background: url("../../img/header.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 625px;
  }

  @media screen and (min-width: $breakpoint-xl) {
    height: 725px;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    height: calc(100vh - 90px);
  }

  @media screen and (min-width: 1921px) {
    background-size: cover;
  }

  &_title {
    color: $color-primary-light;
    text-transform: uppercase;

    // animation: fadeInLeft 1s ease-in 1 forwards;

    @media screen and (min-width: $breakpoint-xl) and (max-width: 1600px) {
      font-size: 70px;
    }

    p {
      background-color: $color-primary-base;
      line-height: 1;
      letter-spacing: 0px;
      display: inline-block;
      border-radius: 10px;
      padding: 10px 15px;
      &:first-child {
        border-radius: 10px 10px 0px 0px;
        margin: 60px auto 0px;
      }

      @media screen and (min-width: $breakpoint-lg) {
        display: inline;
        border-radius: 10px;
        padding: 0px 10px;
        text-align: left;
        margin: 0 0;
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      position: absolute;
      top: auto;
      bottom: 50px;
    }
  }
  &_btnAbsolute {
    width: 100%;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: auto;
    margin: auto;
    animation: fadeIn 1s ease-in 1 forwards;
    text-align: center;

    @media screen and (min-width: $breakpoint-lg) {
      width: auto;
      text-align: end;
      right: 30px;
      left: auto;
    }
  }
}

.header_v2 {
  padding: 50px 0px;
  background-color: $color-primary-base;
  height: calc(100vh - 110px);
  padding: 25px 0px;

  @media screen and (min-width: $breakpoint-md) {
    z-index: 70;
    height: calc(100vh - 125px);
    padding: 35px 0px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    height: calc(100vh - 130px);
    padding: 50px 0px;
  }

  &_title {
    width: 100%;
    color: $color-white;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0px;
    display: inline-block;

    div {
      width: 20rem;

      @media screen and (min-width: $breakpoint-md) {
        width: 45rem;
      }

      @media screen and (min-width: $breakpoint-lg) {
        width: 40rem;
      }

      @media screen and (min-width: $breakpoint-xl) {
        width: 55rem;
      }
    }

    p {
      padding: 5px 18px;
      display: inline-flex;
      background-color: rgba($color-primary-base, 0.8);
      border-radius: 0px 0px 10px 0px;
      line-height: 1;

      &:first-of-type {
        margin-top: 0 !important;
        border-radius: 10px 10px 10px 0px;
      }

      &:last-of-type {
        border-radius: 0px 0px 10px 10px;
      }

      .rounded {
        border-radius: 10px !important;
      }
    }

    @media screen and (min-width: $breakpoint-xl) and (max-width: 1600px) {
      font-size: 70px;
    }

    @media screen and (min-width: $breakpoint-lg) {
      text-align: left;
      margin: 0 0;
    }
  }

  &_text {
    color: $color-white;
    margin-top: 1.25rem;
    max-width: 55rem;
    font-size: 1.125rem;
    font-weight: 300;

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 1.75rem;
      font-size: 1.25rem;
      padding: 0px 10px;
    }

    @media screen and (min-width: $breakpoint-xl) {
      margin-top: 2.375rem;
      font-size: 1.5rem;
    }
  }

  &_video {
    inset: 0;
    width: 100%;
    height: 100%;
    max-height: 85%;
    object-fit: cover;

    @media screen and (min-width: $breakpoint-lg) {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  &_img {
    inset: 0;
    width: 100%;
    height: 100%;
    max-height: 85%;
    object-fit: cover;

    @media screen and (min-width: $breakpoint-lg) {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  &_overlay {
    background: linear-gradient(
      33.84deg,
      #00281e -9.39%,
      rgba(0, 102, 77, 0) 57.68%
    );
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &_mark {
    display: inline-flex;
    background-color: $color-primary-light;
    color: $color-primary-base;
    padding: 1px 10px;
    margin-bottom: 15px;
    border-radius: 999px;
    font-size: 1.25rem;

    @media screen and (min-width: $breakpoint-md) {
      padding: 3px 20px;
      margin-bottom: 15px;
      font-size: 2.625rem;
    }
  }

  &_mobile {
    @media screen and (max-width: $breakpoint-lg-max) {
      max-height: 85%;

      .header_v2_btn {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        text-align: center;
        .btn {
          transform: translateY(90%);
        }
      }

      .header_v2_overlay {
        z-index: -1;
        margin: -50px -1.5rem -24px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md-max) {
    .header_v2_overlay {
      margin: -50px -0.75rem -19px;
    }
  }
}
