.splide {
  $c: &;
  cursor: grab;
  &__slide {
    width: 100%;
    max-width: 200px;

    @media screen and (min-width: $breakpoint-md) {
      max-width: 300px;
    }

    @media screen and (min-width: $breakpoint-xl) {
      max-width: 400px;
    }

    h3 {
      @media screen and (min-width: $breakpoint-xxl) {
        font-size: $fs-xxxl;
      }
    }

    p {
      height: 120px;
      overflow: hidden;
      @media screen and (min-width: $breakpoint-md) {
        height: 180px;
      }
    }
    &__full {
      max-width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        max-width: 100%;
      }

      @media screen and (min-width: $breakpoint-xl) {
        max-width: 100%;
      }
    }
  }
  &_custom {
    position: static;
    .splide__pagination {
      gap: 5px;
      justify-content: flex-start;
      padding-left: 0px;
      @media screen and (min-width: $breakpoint-lg) {
        position: absolute;
        left: 0;
        bottom: 0;
        gap: 10px;
      }
    }
    .splide__pagination__page {
      width: 12px;
      height: 12px;
      background-color: $color-primary-base;
      border-radius: 999px;
      border: none;
      transition: width 0.5s ease;

      &.is-active {
        width: 24px;
      }

      @media screen and (min-width: $breakpoint-lg) {
        width: 34px;
        height: 34px;
        &.is-active {
          width: 68px;
        }
      }
    }
    .splide__arrow--prev {
      display: none;
    }
    .splide__arrow {
      background: transparent;
      border: none;

      &--next {
        position: absolute;
        bottom: -15px;
        right: 15px;
        background-color: $color-primary-light;
        border-radius: 999px;
        padding: 10px;
        transition: all 0.5s ease;
        svg {
          width: 24px;
          height: 24px;

          path {
            fill: $color-primary-base;
          }
        }

        &:active {
          transform: translateX(10px);
        }
      }
    }
  }
}

.newSplide {
  padding-top: 64px;
  .splide__arrows {
    margin-left: 10px;
  }
  .splide__arrow {
    background: transparent;
    border: none;

    &--next,
    &--prev {
      background-color: $color-primary-light;
      border-radius: 999px;
      padding: 10px;
      transition: all 0.5s ease;
      svg {
        width: 24px;
        height: 24px;
        transform: translateX(2px);
        path {
          stroke: $color-primary-base;
        }

        @media (min-width: 768px) {
          width: 36px;
          height: 36px;
        }
      }
    }

    @media (min-width: 768px) {
      padding: 14px;
      &:hover {
        transform: translateX(10px);
      }
    }

    &:active {
      transform: translateX(10px);
    }

    &--prev {
      svg {
        transform: translateX(-2px) rotate(180deg);
      }

      @media (min-width: 768px) {
        &:hover {
          transform: translateX(-10px);
        }
      }

      &:active {
        transform: translateX(-10px);
      }
    }
  }
}
