.accordion{
    &-item{
        padding: 47px 0px 50px;
        border-bottom: 1px solid $color-primary-base!important;
        
        &:first-child{
            border-top: 1px solid $color-primary-base!important;
        }

        @media screen and (min-width: $breakpoint-md) {
            border-bottom: 2px solid $color-primary-base!important;
            &:first-child{
                border-top: 2px solid $color-primary-base!important;
            }
        }

        @media screen and (min-width: $breakpoint-xxl) {
            border-bottom: 3px solid $color-primary-base!important;
            &:first-child{
                border-top: 3px solid $color-primary-base!important;
            }
        }
    }
    &-header{
        button{
            font-size: $fs-base;
            padding: 0 10px 0px 0px;
            color: $color-primary-base;
            line-height: 1.3;
            font-weight: 300;

            @media screen and (min-width: $breakpoint-md) {
                font-size: $fs-xxl;
            }

            @media screen and (min-width: $breakpoint-xxl) {
                font-size: $fs-xxxl;
            }

            &:focus{
                border: none;
                box-shadow: none;
                outline: none;
            }

            &::after{
                width: 35px;
                height: 35px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 96 96' fill='none'%3E%3Cline y1='47' x2='96' y2='47' stroke='%2300664E' stroke-width='4'/%3E%3Cline x1='49' y1='4.37114e-08' x2='49' y2='96' stroke='%2300664E' stroke-width='4'/%3E%3C/svg%3E");
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                    width: 60px;
                    height: 60px;
                }

                @media screen and (min-width: $breakpoint-lg) {
                    width: 70px;
                    height: 70px;
                }
            }

            &:not(.collapsed){
                color: $color-primary-base;
                background-color: transparent;
                box-shadow: none;
                &::after{
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 96 96' fill='none'%3E%3Cline y1='47' x2='96' y2='47' stroke='%2300664E' stroke-width='4'/%3E%3Cline x1='49' y1='4.37114e-08' x2='49' y2='96' stroke='%2300664E' stroke-opacity='0.01' stroke-width='4'/%3E%3C/svg%3E");            
                }
            }
        } 
    }
    &-body{
        font-size: $fs-s;
        padding: 0;
        padding-top: 30px;
        color: $color-primary-base;
        font-weight: $fw-light;

        @media screen and (min-width: $breakpoint-md) {
            font-size: $fs-base;
            padding-top: 40px;
        }

        @media screen and (min-width: $breakpoint-lg) {
            font-size: $fs-xl;
            padding-top: 50px;
        }


        @media screen and (min-width: $breakpoint-xxl) {
            font-size: $fs-xxl;
            
        }
    }
}