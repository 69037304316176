@mixin make-heading($desktop, $tablet, $mobile, $line-height, $font-weight: 700) {
  margin-top: 0;
  margin-bottom: 0rem;
  font-family: $font;
  font-weight: #{$font-weight};
  font-size: #{$mobile};
  line-height: #{$line-height};
  text-transform: uppercase;
	@media (min-width: $breakpoint-md) {
		font-size: #{$tablet};
	}
  @media (min-width: $breakpoint-xl) {
    font-size: #{$desktop};
  }
}

h1, .h1 {
  @include make-heading($fs-h1, $fs-xxxxl, $fs-xxl, $lh-base, $fw-light);
}
h2, .h2 {
  @include make-heading($fs-xxxxl, $fs-xxxl, $fs-xxl, $lh-base, $fw-light);
}
h3, .h3 {
  @include make-heading($fs-xxl, $fs-xl, $fs-l, $lh-base, $fw-light);
}
h4, .h4 {
  @include make-heading($fs-l, $fs-m, $fs-base, $lh-base, $fw-light);
}
h5, .h5 {
  @include make-heading($fs-m, $fs-m, $fs-base, $lh-base, $fw-light);
}
h6, .h6 {
  @include make-heading($fs-base, $fs-base, $fs-base, $lh-base, $fw-light);
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}
